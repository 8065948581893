import { Component, OnInit, NgZone} from '@angular/core';
import { fromEvent, merge, interval, Observable, of, Subscription } from 'rxjs'
import { switchMap, take, skipWhile, takeLast, skipUntil, tap } from 'rxjs/operators'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  //seconds
  inactivityTime: number = 300;

  timeLapsedSinceInactivity: number = 0;
  subscription: Subscription;
  observeable$: Observable<any>;
  mergedObservable$: Observable<any>;

  public inactivityTimerEvent: Array<any>[] = [[document, 'click'], [document, 'wheel'], [document, 'scroll'], [document, 'mousemove'], [document, 'keyup'], [window, 'resize'], [window, 'scroll'], [window, 'mousemove']];

  constructor(
    public _ngZone: NgZone
    ) {}

  ngOnInit() {
    let observableArray$: Observable<any>[] = [];
    this.inactivityTimerEvent.forEach(x => {
      observableArray$.push(fromEvent(x[0], x[1]))
    })
    this.mergedObservable$ = merge(...observableArray$);

    this.createObservable();
  }

  createObservable(): void {
    this._ngZone.runOutsideAngular(() => {

      this.observeable$ = this.mergedObservable$
      .pipe(
        switchMap(ev => interval(1000).pipe(take(this.inactivityTime))),
        skipWhile((x) => {
          this.timeLapsedSinceInactivity = x;
          return x != this.inactivityTime - 1
        })
      );

      this.subscribeObservable();

    })

  }

  subscribeObservable() {
    this.subscription = this.observeable$.subscribe((x) => {
      console.log(`subscribed for ${x + 1} sec`);
      this.unsubscribeObservable()
    })
  }

  unsubscribeObservable() {
   //alert('Your session has expired. Press OK to reload the page and reauthenticate.')
   this.subscription.unsubscribe();
   localStorage.clear();
   location.reload();
  }
}