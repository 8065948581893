// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const signInConfig = {
  WEB_PROTOCOL:'https://',
  // CHANGE ACCORDINGLY FOR EVERY PROJECT
  // **** START ****
  DOMAIN_NAME: 'linksh65b7d411.auth.ap-southeast-1.amazoncognito.com',
  CLIENT_ID: '6go85r5kjloej4negu5do0ss81',
  RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  REDIRECT_SIGN_IN: 'https://site.mer.ph',
  REDIRECT_SIGN_OUT: 'https://site.mer.ph',
  SCOPE: ['email', 'openid'],
  // **** END ****

  //DOMAIN_NAME: 'hht-web-test.auth.ap-southeast-1.amazoncognito.com',
  //CLIENT_ID: '5e9gragu3tlp81e2agtnph2f3b',
  //RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  //REDIRECT_SIGN_IN: 'https://d20qe1nu9mp62o.cloudfront.net/login',
  //REDIRECT_SIGN_OUT: 'https://d20qe1nu9mp62o.cloudfront.net',
  //SCOPE: ['email', 'openid'],
};

export const environment = {
  production: false,

  app_version : '1.0.0',
  // CHANGE ACCORDINGLY FOR EVERY PROJECT
  // **** START ****
  homepage: '/sample',
  // hostURL: 'https://ozkxu121x4.execute-api.ap-southeast-1.amazonaws.com/dev',
  hostURL: 'https://egunxc3l39.execute-api.ap-southeast-1.amazonaws.com/dev',
  // **** END ****

  // AMPLIFY environments
  domainName: `${signInConfig.DOMAIN_NAME}`,
  clientId: `${signInConfig.CLIENT_ID}`,
  responseType: `${signInConfig.RESPONSE_TYPE}`,
  redirectUri: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignIn: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignOut: `${signInConfig.REDIRECT_SIGN_OUT}`,
  scope: `${signInConfig.SCOPE}`,
  signIn: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?client_id=${signInConfig.CLIENT_ID}&response_type=${signInConfig.RESPONSE_TYPE}&scope=email+openid&redirect_uri=${signInConfig.REDIRECT_SIGN_IN}`,
  signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/logout?client_id=${signInConfig.CLIENT_ID}&logout_uri=${signInConfig.REDIRECT_SIGN_OUT}`
};
