import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponentComponent } from '@shared/loading-component/loading-component.component';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ConfirmComponent } from './modals/confirm/confirm.component';
import { InformationComponent } from './modals/information/information.component';
import { TimedComponent } from './modals/timed/timed.component';
import { MapComponent } from './modals/map/map.component';
import { GoogleMapsModule ,GoogleMap} from '@angular/google-maps';
import { CarouselComponent } from './modals/carousel/carousel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        GoogleMapsModule,
        NgbModule,
        NgxSpinnerModule
    ],
    exports: [
        LoadingComponentComponent
    ],
    declarations: [
        LoadingComponentComponent,
        ConfirmComponent,
        InformationComponent,
        TimedComponent,
        MapComponent,
        CarouselComponent
    ],
    providers: [
        DatePipe,
        GoogleMap,
    ]
})
export class SharedModule { }
